import { useState, useEffect, useCallback, useContext } from "react";
import { getFirestoreCards } from "../auth/firestore/getFirestoreCards";
import { StoreCard } from "../../types/detailsTypes";
import { UserContext } from "../../contexts/user-context";

export const useStoreCards = () => {
  const [storeCards, setStoreCards] = useState<StoreCard[]>([]);
  const [filteredCards, setFilteredCards] = useState<StoreCard[]>([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Get userID from context
  const context = useContext(UserContext);
  const userID = context?.user?.uid || null;

  /**
   * Fetch and store all storeCards from Firestore.
   */
  const fetchStoreCards = useCallback(async () => {
    if (!userID) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const cards = await getFirestoreCards(userID);
      setStoreCards(cards);
      setFilteredCards(cards);
      if (cards.length === 0) {
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching store cards:", error);
    } finally {
      setLoading(false);
    }
  }, [userID]);

  useEffect(() => {
    fetchStoreCards();
  }, [fetchStoreCards]);

  /**
   * Filter the in-memory storeCards array.
   */
  const filterCards = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = storeCards.filter((card) =>
      card.storeName.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredCards(filtered);
    if (filtered.length === 0) {
      setSnackbarOpen(true);
    }
  };

  return {
    filteredCards,
    loading,
    filterCards,
    snackbarOpen,
    setSnackbarOpen,
    fetchStoreCards,
  };
};
