// firebaseConfig.ts

import { initializeApp, FirebaseOptions } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAuth, Auth } from "firebase/auth";

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY || "",
  authDomain: "auth.stomployalty.com",
  projectId: process.env.REACT_APP_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_storage_bucket || "",
  messagingSenderId: process.env.REACT_APP_messaging_sender_id || "",
  appId: process.env.REACT_APP_app_id || "",
  measurementId: process.env.REACT_APP_measurement_id || "",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storageRef = getStorage(app);
const functions = getFunctions(app);
const auth: Auth = getAuth(app);

export { app, db, storageRef, auth, functions };
