// SignInWithGoogle.jsx

import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../../../firebaseConfig";

import "./google-sign-in.css";

const SignInWithGoogle = ({ shortID }) => {
  const navigate = useNavigate();
  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);

      // If needed, you can access token/user data:
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // const user = result.user;

      // If shortID is present, redirect to "add-to-wallet" page for that shortID
      // otherwise, go to your default route.
      if (shortID) {
        navigate(`/card/${shortID}`);
      } else {
        navigate("/");
      }
    } catch (error) {
      // Handle errors from signInWithPopup
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      // Optionally display an error alert or message
    }
  };

  return (
    <button
      type="button"
      className="login-with-google-btn"
      onClick={handleSignInWithGoogle}
    >
      Sign in with Google
    </button>
  );
};

export default SignInWithGoogle;
