import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { StoreCard } from "../../../types/detailsTypes";

export const getFirestoreCards = async (
  userID: string
): Promise<StoreCard[]> => {
  const storeCards: any[] = [];

  try {
    // 1) Get user's customerLoyaltyCards subcollection
    const userRef = doc(db, "users", userID);
    const customerLoyaltyCardsRef = collection(userRef, "customerLoyaltyCards");
    const customerLoyaltyCardsSnap = await getDocs(customerLoyaltyCardsRef);

    // 2) Iterate through each doc in customerLoyaltyCards
    for (const clcDoc of customerLoyaltyCardsSnap.docs) {
      const clcData = clcDoc.data();
      const storeID = clcData.storeID;

      if (!storeID) {
        // If storeID is missing for some reason, skip
        continue;
      }

      // 3) Fetch the store doc from /stores/{storeID}
      const storeDocRef = doc(db, "stores", storeID);
      const storeDocSnap = await getDoc(storeDocRef);
      const storeData = storeDocSnap.exists() ? storeDocSnap.data() : {};

      // 4) Fetch the loyalty card doc from the store’s loyaltyCards subcollection
      const loyaltyCardDocRef = doc(
        db,
        "stores",
        storeID,
        "loyaltyCards",
        clcDoc.id
      );
      const loyaltyCardDocSnap = await getDoc(loyaltyCardDocRef);
      const loyaltyCardData = loyaltyCardDocSnap.exists()
        ? loyaltyCardDocSnap.data()
        : {};

      // 5) Merge data with the correct priority:
      const mergedData = {
        ...clcData,
        ...storeData,
        ...loyaltyCardData,
      };

      // 6) Spread mergedData into the returned object
      storeCards.push({
        ...mergedData,
        id: clcDoc.id,
        scans: clcData.scans || 0,
      });
    }
  } catch (error) {
    console.error("Error fetching store cards: ", error);
    return [];
  }

  return storeCards;
};
