import React, { useState } from "react";
import { TextField, Snackbar, Box } from "@mui/material";
import MainCardsDisplay from "../components/wallet/MainCardsDisplay";
import { useStoreCards } from "../components/wallet/useStoreCards";
import { QrButton } from "../components/lib/fabButtons";

const WalletPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { filteredCards, loading, filterCards, snackbarOpen, setSnackbarOpen } =
    useStoreCards();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterCards(query);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "600px",
      }}
    >
      <TextField
        label="Search Loyalty Card"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        autoComplete="off"
        sx={{
          borderRadius: "60px",
          marginBottom: "15px",
          width: "100%",
          maxWidth: "400px",
          "& .MuiInputBase-root": {
            borderRadius: "60px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            "& input": {
              color: "white",
              paddingLeft: "25px",
            },
          },
          "& .MuiInputLabel-root": {
            color: "rgba(255, 255, 255, 0.7)",
            transform: "translate(24px, 16px) scale(1)",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "primary.main",
            transform: "translate(16px, -9px) scale(0.75)",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "60px",
          },
        }}
      />
      <MainCardsDisplay cards={filteredCards} loading={loading} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="No loyalty card found."
      />
      <Box
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(5),
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 10,
        }}
      >
        <QrButton />
      </Box>
    </div>
  );
};

export default WalletPage;
