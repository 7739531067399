import React, { useEffect, useState } from "react";
import { Box, Button, Tooltip, SxProps, Theme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import { User } from "firebase/auth";
import {
  UserProfile,
  updateBirthday,
} from "../../components/auth/firestore/getUserDetails";

interface BirthdayFieldProps {
  user: User | null;
  userData: UserProfile | null;
  setUserData: React.Dispatch<React.SetStateAction<UserProfile>>;
  textFieldSx: SxProps<Theme>;
}

const BirthdayField: React.FC<BirthdayFieldProps> = ({
  user,
  userData,
  setUserData,
  textFieldSx,
}) => {
  const [editBirthday, setEditBirthday] = useState<Dayjs | null>(null);
  const [isEditingBirthday, setIsEditingBirthday] = useState(false);

  useEffect(() => {
    if (userData?.birthday) {
      setEditBirthday(dayjs(userData.birthday));
    }
  }, [userData]);

  const handleSetBirthday = (newDate: Dayjs | null) => {
    setEditBirthday(newDate);
  };

  const handleButtonClick = async () => {
    if (!user?.uid) return;

    if (userData?.birthday) {
      // Birthday is already set, do nothing
      alert("Birthday already set. You cannot change it.");
      return;
    }

    if (!isEditingBirthday) {
      // If not editing, toggle to editing mode
      setIsEditingBirthday(true);
    } else {
      // If editing, update Firestore
      if (!editBirthday) {
        alert("Please select a date before updating.");
        return;
      }

      try {
        await updateBirthday(user.uid, editBirthday?.toISOString());
        setUserData((prev) => ({
          ...prev,
          birthday: editBirthday.toISOString(),
        }));
        alert("Birthday updated!");
      } catch (err) {
        alert("Failed to update birthday. " + (err as Error).message);
      } finally {
        setIsEditingBirthday(false);
      }
    }
  };

  // If the user already has a birthday, just show it (read-only)
  if (userData?.birthday) {
    return (
      <Tooltip title="DOB already set" arrow>
        <Box>
          <DatePicker
            label="Birthday"
            // Ensure you convert userData.birthday (which is a string) into a dayjs object
            value={userData?.birthday ? dayjs(userData.birthday) : null}
            disabled
            slotProps={{
              textField: {
                fullWidth: true,
                sx: textFieldSx,
              },
            }}
          />
        </Box>
      </Tooltip>
    );
  }

  // Else show the set/update logic
  return (
    <Box>
      <Tooltip title="You can set your DOB once" arrow>
        <span>
          <DatePicker
            label="Birthday"
            disabled={!isEditingBirthday}
            value={editBirthday}
            onChange={handleSetBirthday}
            slotProps={{
              textField: {
                fullWidth: true,
                sx: textFieldSx,
              },
            }}
          />
        </span>
      </Tooltip>

      <Button
        variant="contained"
        sx={{ mt: 2, fontWeight: "bold", width: "100%" }}
        onClick={handleButtonClick}
      >
        {isEditingBirthday ? "Update Birthday" : "Set Birthday"}
      </Button>
    </Box>
  );
};

export default BirthdayField;
