import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CircularProgress } from "@mui/material";

import { theme } from "./theme/theme";
import { UserProvider } from "./contexts/user-context";
import { UserContext } from "./contexts/user-context";

import { AuthGuard } from "./components/auth/guards/auth-guard";
import { GuestGuard } from "./components/auth/guards/guest-guard";

// Pages
import SignInPage from "./pages/signInPage";
import SignUpPage from "./pages/signUpPage";
import ResetPasswordPage from "./pages/resetPasswordPage";
import WalletPage from "./pages/walletPage";
import WalletStorePage from "./pages/dynamicWallet/offers/WalletStorePage";
import AddToWalletPage from "./pages/addToWalletPage";

// Layouts
import GuestLayout from "./components/main/GuestLayout";
import AuthLayout from "./components/main/AuthLayout";
import ThinHeader from "./components/main/thinHeader";
import Account from "./pages/account/accountPage";

export default function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <ThinHeader />

          <MainContent />
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

function MainContent() {
  const { isLoading } = React.useContext(UserContext) ?? {};

  if (isLoading) {
    return (
      <Box
        sx={{
          minHeight: "calc(100vh - 35px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000912",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Else, render normal routes
  return (
    <Routes>
      <Route
        element={
          <GuestGuard>
            <Outlet />
          </GuestGuard>
        }
      >
        <Route element={<GuestLayout />}>
          <Route path="/auth/sign-in" element={<SignInPage />} />
          <Route path="/auth/sign-up" element={<SignUpPage />} />
          <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
        </Route>
      </Route>

      <Route
        element={
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        }
      >
        <Route element={<AuthLayout />}>
          <Route
            path="/wallet"
            element={
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <WalletPage />{" "}
              </Box>
            }
          />
          <Route path="/wallet/:storeURL" element={<WalletStorePage />} />
          <Route path="/profile" element={<Account />} />
        </Route>
      </Route>

      <Route
        path="card/:shortID"
        element={
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ThinHeader />
            <AddToWalletPage />
          </Box>
        }
      />
      <Route path="*" element={<Navigate to="/wallet" />} />
    </Routes>
  );
}
