// import React, { useContext, useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   TextField,
//   Tooltip,
//   Typography,
//   Dialog,
//   DialogContent,
//   DialogActions,
//   SxProps,
//   Theme,
// } from "@mui/material";

// // MUI X (v6+) Date Pickers with Day.js
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import dayjs, { Dayjs } from "dayjs";

// // Firebase Phone Auth (v9+)
// import {
//   RecaptchaVerifier,
//   signInWithPhoneNumber,
//   ConfirmationResult,
// } from "firebase/auth";
// import { auth } from "../../firebaseConfig";

// // Firestore update functions
// import {
//   updateName,
//   updateBirthday,
//   updatePhone,
//   UserProfile,
// } from "../../components/auth/firestore/getUserDetails";

// // User context
// import { UserContext } from "../../contexts/user-context";

// // Ensure good contrast on dark backgrounds
// const textFieldSx: SxProps<Theme> = {
//   "& .MuiInputBase-root": {
//     color: "#fff",
//   },
//   "& .MuiFormLabel-root": {
//     color: "#aaa",
//   },
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderColor: "#aaa",
//   },
//   "&:hover .MuiOutlinedInput-notchedOutline": {
//     borderColor: "#fff",
//   },
//   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//     borderColor: "#fff",
//   },
//   "& .MuiInputBase-input.Mui-disabled": {
//     WebkitTextFillColor: "#fff",
//   },
//   "& .MuiFormLabel-root.Mui-disabled": {
//     color: "#ccc",
//   },
//   "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
//     borderColor: "#666",
//   },
// };

// declare global {
//   interface Window {
//     recaptchaVerifier?: RecaptchaVerifier;
//   }
// }

// const Account: React.FC = () => {
//   const userContext = useContext(UserContext);
//   if (!userContext) {
//     throw new Error(
//       "UserContext is undefined. Make sure UserProvider is included."
//     );
//   }
//   const { user, userData, setUserData } = userContext;

//   const [editName, setEditName] = useState("");
//   const [editPhone, setEditPhone] = useState("");
//   const [editBirthday, setEditBirthday] = useState<Dayjs | null>(null);

//   const [otpOpen, setOtpOpen] = useState(false);
//   const [confirmationResult, setConfirmationResult] =
//     useState<ConfirmationResult | null>(null);
//   const [otpCode, setOtpCode] = useState("");

//   // Initialize fields from userData
//   useEffect(() => {
//     if (!userData) return;
//     const combinedName = `${userData.firstName ?? ""} ${
//       userData.lastName ?? ""
//     }`.trim();
//     setEditName(combinedName);
//     setEditPhone(userData.phone ?? "");
//     if (userData.birthday) {
//       setEditBirthday(dayjs(userData.birthday));
//     }
//   }, [userData]);

//   // Update name
//   const handleUpdateName = async () => {
//     if (!user?.uid) return;
//     const [firstName, ...rest] = editName.trim().split(" ");
//     const lastName = rest.join(" ");
//     try {
//       await updateName(user.uid, firstName, lastName);
//       setUserData((prev: UserProfile) => ({
//         ...prev,
//         firstName,
//         lastName,
//       }));
//       alert("Name updated!");
//     } catch (error) {
//       alert("Failed to update name.");
//     }
//   };

//   // Send OTP - Make sure phone is in E.164 format (+1..., +91..., etc.)
//   const handleSendOtp = () => {
//     if (!user?.uid) return;
//     if (!editPhone) {
//       alert(
//         "Please enter a phone number in full international format, e.g. +15555551234"
//       );
//       return;
//     }

//     // If the reCAPTCHA object isn't initialized yet, do so
//     if (!window.recaptchaVerifier) {
//       window.recaptchaVerifier = new RecaptchaVerifier(
//         auth,
//         "recaptcha-container",
//         {
//           size: "invisible",
//         }
//       );
//     }

//     const appVerifier = window.recaptchaVerifier;
//     signInWithPhoneNumber(auth, editPhone, appVerifier)
//       .then((result) => {
//         setConfirmationResult(result);
//         setOtpOpen(true);
//       })
//       .catch((err) => {
//         alert("Error sending OTP: " + err.message);
//       });
//   };

//   // Verify the OTP, then update phone in Firestore
//   const handleVerifyOtp = async () => {
//     if (!user?.uid || !confirmationResult) return;

//     try {
//       await confirmationResult.confirm(otpCode);
//       await updatePhone(user.uid, editPhone);
//       setUserData((prev: UserProfile) => ({
//         ...prev,
//         phone: editPhone,
//       }));
//       setOtpOpen(false);
//       setOtpCode("");
//       alert("Phone number updated!");
//     } catch (err) {
//       alert("Invalid OTP. Please try again.");
//     }
//   };

//   // One-time birthday update
//   const handleUpdateBirthday = async (newDate: Dayjs | null) => {
//     if (!user?.uid || !newDate) return;
//     if (userData?.birthday) return; // Already set

//     try {
//       await updateBirthday(user.uid, newDate.toISOString());
//       setUserData((prev: UserProfile) => ({
//         ...prev,
//         birthday: newDate.toISOString(),
//       }));
//       setEditBirthday(newDate);
//       alert("Birthday updated!");
//     } catch (err) {
//       alert("Failed to update birthday.");
//     }
//   };

//   return (
//     <Container
//       sx={{
//         // Add some margin so it's not flush against the screen edges
//         mt: 4,
//         mb: 4,
//         mx: "auto",
//         maxWidth: { xs: "100%", sm: "500px", md: "600px" },
//         backgroundColor: "rgba(255, 255, 255, 0.1)",
//         backdropFilter: "blur(2px)",
//         borderRadius: 2,
//         p: { xs: 2, md: 4 },
//         color: "#fff",
//         boxShadow: "0 0 10px rgba(0,0,0,0.3)",
//       }}
//     >
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <Typography
//           variant="h4"
//           mb={4}
//           sx={{ fontWeight: "bold", textAlign: "center" }}
//         >
//           Account Details
//         </Typography>

//         {/* Name Field */}
//         <Box mb={3}>
//           <TextField
//             label="Name"
//             variant="outlined"
//             fullWidth
//             value={editName}
//             onChange={(e) => setEditName(e.target.value)}
//             sx={textFieldSx}
//           />
//           <Button
//             variant="contained"
//             sx={{ mt: 2, fontWeight: "bold", width: "100%" }}
//             onClick={handleUpdateName}
//           >
//             Update Name
//           </Button>
//         </Box>

//         {/* Phone Field + OTP */}
//         <Box mb={3}>
//           <TextField
//             label="Phone Number (+1..., +91..., etc.)"
//             variant="outlined"
//             fullWidth
//             value={editPhone}
//             onChange={(e) => setEditPhone(e.target.value)}
//             sx={textFieldSx}
//           />
//           <Button
//             variant="contained"
//             sx={{ mt: 2, fontWeight: "bold", width: "100%" }}
//             onClick={handleSendOtp}
//           >
//             Send OTP
//           </Button>
//         </Box>

//         {/* Email (read-only) */}
//         <Box mb={3}>
//           <TextField
//             label="Email"
//             variant="outlined"
//             fullWidth
//             value={userData?.email || ""}
//             disabled
//             sx={textFieldSx}
//           />
//         </Box>

//         {/* DOB (DatePicker) */}
//         <Box mb={3}>
//           <Tooltip
//             title={
//               userData?.birthday
//                 ? "DOB already set"
//                 : "You can set your DOB once"
//             }
//             arrow
//           >
//             <span>
//               <DatePicker
//                 label="Birthday"
//                 value={editBirthday}
//                 onChange={(date) => {
//                   if (!userData?.birthday) {
//                     setEditBirthday(date);
//                     handleUpdateBirthday(date);
//                   }
//                 }}
//                 disabled={Boolean(userData?.birthday)}
//                 slotProps={{
//                   textField: {
//                     fullWidth: true,
//                     sx: textFieldSx,
//                   },
//                 }}
//               />
//             </span>
//           </Tooltip>
//         </Box>

//         {/* Referral Code (read-only) */}
//         <Box mb={3}>
//           <TextField
//             label="Referral Code"
//             variant="outlined"
//             fullWidth
//             value={userData?.referralCode || ""}
//             disabled
//             sx={textFieldSx}
//           />
//         </Box>
//       </LocalizationProvider>

//       {/* Hidden container for ReCAPTCHA */}
//       <div id="recaptcha-container" style={{ display: "none" }} />

//       {/* OTP Dialog */}
//       <Dialog
//         open={otpOpen}
//         onClose={() => setOtpOpen(false)}
//         PaperProps={{
//           sx: {
//             backgroundColor: "#1a1a1a",
//             color: "#fff",
//           },
//         }}
//       >
//         <DialogContent>
//           <TextField
//             label="Enter OTP"
//             variant="outlined"
//             fullWidth
//             value={otpCode}
//             onChange={(e) => setOtpCode(e.target.value)}
//             sx={textFieldSx}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOtpOpen(false)} sx={{ color: "#fff" }}>
//             Cancel
//           </Button>
//           <Button onClick={handleVerifyOtp} variant="contained">
//             Verify
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default Account;

import React, { useContext } from "react";
import { Container, Typography, Box, SxProps, Theme } from "@mui/material";

// MUI X (v6+) Date Pickers with Day.js
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Context
import { UserContext } from "../../contexts/user-context";

// Sub-components
import NameField from "./NameUpdate";
import PhoneField from "./PhoneVerification";
import BirthdayField from "./BirthdayUpdate";
import ReadOnlyFields from "./ReadOnlyFields";

// Example MUI styles to keep consistent
export const textFieldSx: SxProps<Theme> = {
  "& .MuiInputBase-root": {
    color: "#fff",
  },
  "& .MuiFormLabel-root": {
    color: "#aaa",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#aaa",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#fff",
  },
  "& .MuiFormLabel-root.Mui-disabled": {
    color: "#ccc",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "#666",
  },
};

const Account: React.FC = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error(
      "UserContext is undefined. Make sure UserProvider is included."
    );
  }
  const { user, userData, setUserData } = userContext;

  return (
    <Container
      sx={{
        mt: 4,
        mb: 4,
        mx: "auto",
        maxWidth: { xs: "100%", sm: "500px", md: "600px" },
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(2px)",
        borderRadius: 2,
        p: { xs: 2, md: 4 },
        color: "#fff",
        boxShadow: "0 0 10px rgba(0,0,0,0.3)",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography
          variant="h4"
          mb={4}
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Account Details
        </Typography>

        <Box mb={3}>
          <NameField
            user={user}
            userData={userData}
            setUserData={setUserData}
            textFieldSx={textFieldSx}
          />
        </Box>

        {/* <Box mb={3}>
          <PhoneField
            user={user}
            userData={userData}
            setUserData={setUserData}
            textFieldSx={textFieldSx}
          />
        </Box> */}

        <Box mb={3}>
          <ReadOnlyFields userData={userData} textFieldSx={textFieldSx} />
        </Box>

        <Box mb={3}>
          <BirthdayField
            user={user}
            userData={userData}
            setUserData={setUserData}
            textFieldSx={textFieldSx}
          />
        </Box>
      </LocalizationProvider>
    </Container>
  );
};

export default Account;
