import React from "react";
import { Outlet } from "react-router-dom";
import ThinHeader from "./thinHeader";
import { Box } from "@mui/material";

const GuestLayout: React.FC = () => {
  return (
    <Box sx={{ pt: "50px" }}>
      <ThinHeader />
      <Outlet />
    </Box>
  );
};

export default GuestLayout;
