import AddToWalletStoreFront from "./addtoWalletStoreFront";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";

export default function AddToWalletPage() {
  const { shortID } = useParams<{ shortID: string }>();
  const navigate = useNavigate();
  if (!shortID) {
    navigate("/wallet");
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Stack spacing={1}>
        {shortID && <AddToWalletStoreFront shortID={shortID} />}
      </Stack>
    </Box>
  );
}
