import React, { useState } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Collapse,
  Button,
  Divider,
  Skeleton,
} from "@mui/material";
import { Link } from "react-router-dom";
import defaultIcon from "../../../assets/defaultIcon.svg";
import StampCard from "../stampCard";
import { StoreCard } from "../../../types/detailsTypes";

interface CardPopupProps {
  card: StoreCard;
}

const FrontPopUp: React.FC<CardPopupProps> = ({ card }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showRewards, setShowRewards] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const currentScans = card.scans;
  const scansRemaining = card.maxScans - currentScans;

  return (
    <Box
      sx={{
        backgroundColor: card.cardBackgroundColor,
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        overflow: "hidden",
        width: "19rem",
        height: "auto",
        padding: 0,
      }}
    >
      {/* Title Image Section */}
      {card.titleImage && (
        <>
          {!imageLoaded && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="auto"
              sx={{
                aspectRatio: "2/1",
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
              }}
            />
          )}

          <Box sx={{ display: imageLoaded ? "block" : "none" }}>
            <CardMedia
              component="img"
              image={card.titleImage}
              alt={`${card.storeName} promotion`}
              onLoad={() => setImageLoaded(true)}
              sx={{
                width: "100%",
                height: "auto",
                aspectRatio: "2/1",
                objectFit: "cover",
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
              }}
            />
          </Box>
        </>
      )}

      {/* Stamp Section */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <StampCard
          activeStamp={card?.activeStamp || defaultIcon}
          inactiveStamp={card?.inactiveStamp || defaultIcon}
          activeStampColor={card?.activeStampColor || "#000"}
          inactiveStampColor={card?.inactiveStampColor || "#999"}
          stampOutlineColor={card?.stampOutlineColor || "#ccc"}
          stampAreaBackgroundColor={card?.stampAreaBackgroundColor || "#fff"}
          stampBackgroundColor={card?.stampBackgroundColor || "#fff"}
          maxScans={card.maxScans}
          currentScans={card.scans}
          rewards={card.rewards}
        />
      </Box>

      {/* Card Details Section */}
      <Box sx={{ px: 2, textAlign: "left" }}>
        <Box sx={{ py: 1, textAlign: "left" }}>
          <Typography variant="h5" sx={{ color: card?.cardTextColor }}>
            <strong>{card.storeName}</strong>
          </Typography>
          <Divider sx={{ my: 1, borderColor: card.cardTextColor }} />

          <Typography variant="body2" sx={{ color: card.cardTextColor }}>
            <strong>Current Scans:</strong> {currentScans}
          </Typography>
          <Typography variant="body2" sx={{ color: card.cardTextColor }}>
            <strong>Scans Required for Final Reward:</strong> {scansRemaining}
          </Typography>

          {card?.rewards && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowRewards(!showRewards)}
              >
                <Typography
                  variant="body2"
                  sx={{
                    textDecoration: "underline",
                    color: card.cardTextColor,
                  }}
                >
                  <strong>Rewards</strong>
                </Typography>
              </Box>
              <Collapse in={showRewards}>
                <Box
                  sx={{
                    mt: 1,
                    color: card.cardTextColor,
                    fontSize: "0.9rem",
                  }}
                >
                  <ul>
                    {card.rewards
                      .sort((a, b) => a.scansRequired - b.scansRequired)
                      .map((reward, index) => (
                        <li key={index}>
                          {reward.scansRequired} Scans - {reward.reward}
                        </li>
                      ))}
                  </ul>
                </Box>
              </Collapse>
            </>
          )}

          {card?.disclaimer && (
            <Box sx={{ cursor: "pointer", mt: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  textDecoration: "underline",
                  color: card.cardTextColor,
                }}
                onClick={() => setShowDisclaimer(!showDisclaimer)}
              >
                Terms and Conditions
              </Typography>
              <Collapse in={showDisclaimer}>
                <Box sx={{ pr: 1, mt: 1 }}>
                  <ul>
                    {card.disclaimer.split("\n").map((point, index) => (
                      <li key={index}>
                        <Typography
                          variant="body2"
                          sx={{ color: card.cardTextColor }}
                        >
                          {point}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Collapse>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <Link
              to={`/wallet/${card.storeURL}`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" color="primary">
                Explore
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FrontPopUp;
