import React from "react";
import MiniCard from "./MiniCard";
import { StoreCard } from "../../types/detailsTypes";
import { Box, CircularProgress } from "@mui/material";

interface MainCardsDisplayProps {
  cards: StoreCard[];
  loading: boolean;
}

const MainCardsDisplay: React.FC<MainCardsDisplayProps> = ({
  cards,
  loading,
}) => {
  return (
    <Box display="flex" flexWrap="wrap" gap={1.5} justifyContent="center">
      {loading ? (
        <CircularProgress />
      ) : (
        cards.map((card) => <MiniCard key={card.id} card={card} />)
      )}
    </Box>
  );
};

export default MainCardsDisplay;
