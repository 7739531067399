"use client";

import React, { useState } from "react";
import { Box, SxProps, Theme, Typography, Popover } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import muiStamps from "../../reusables/muiStamps";

/**
 * Create a mapping from `muiStamps` for easier lookup.
 */
const iconsMap: Record<string, React.ElementType> = muiStamps.reduce(
  (map, { name, component }) => ({ ...map, [name]: component }),
  {}
);

/**
 * Helper function to determine if a stamp is an icon name.
 */
function isIconName(stamp: string): boolean {
  return Boolean(iconsMap[stamp]);
}

/**
 * Helper function to determine if a string is likely an image URL.
 */
function isImageUrl(stamp: string | undefined): boolean {
  return (
    typeof stamp === "string" &&
    stamp.length > 25 &&
    (stamp.startsWith("http://") || stamp.startsWith("https://"))
  );
}

// StampCard Props
interface StampCardNewProps {
  activeStamp: string;
  inactiveStamp: string;
  activeStampColor: string;
  inactiveStampColor: string;
  stampOutlineColor: string;
  stampAreaBackgroundColor: string;
  stampBackgroundColor: string;
  maxScans: number;
  currentScans: number;
  rewards?: { reward: string; scansRequired: number }[];
}

const StampCardNew: React.FC<StampCardNewProps> = ({
  activeStamp,
  inactiveStamp,
  activeStampColor,
  inactiveStampColor,
  stampOutlineColor,
  stampAreaBackgroundColor,
  stampBackgroundColor,
  maxScans,
  currentScans,
  rewards = [],
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [rewardContent, setRewardContent] = useState<string>("");

  // Open popover for reward
  const handleOpenReward = (
    event: React.MouseEvent<HTMLElement>,
    reward: string
  ) => {
    setAnchorEl(event.currentTarget);
    setRewardContent(reward);
  };

  // Close popover
  const handleCloseReward = () => {
    setAnchorEl(null);
    setRewardContent("");
  };

  const open = Boolean(anchorEl);

  const rewardLookup: Record<number, string> = rewards.reduce(
    (acc: Record<number, string>, { scansRequired, reward }) => {
      acc[scansRequired] = reward;
      return acc;
    },
    {} as Record<number, string> // Provide an initial type for the accumulator
  );

  // Renders a single stamp (either active or inactive)
  const renderStampContent = (isActive: boolean, index: number) => {
    // choose stamp name vs. color
    const stampName = isActive ? activeStamp : inactiveStamp;
    const stampColor = isActive ? activeStampColor : inactiveStampColor;

    // if we have an icon name
    if (isIconName(stampName)) {
      const IconComponent = iconsMap[stampName];
      return (
        <IconComponent
          key={`icon-${index}`}
          sx={{
            color: stampColor,
            width: "80%",
            height: "80%",
          }}
        />
      );
    }

    // if we have an image URL
    if (isImageUrl(stampName)) {
      return (
        <Box
          key={`image-${index}`}
          component="img"
          src={stampName}
          alt={`stamp-${index}`}
          sx={{
            width: "90%",
            height: "90%",
            objectFit: "contain",
          }}
        />
      );
    }

    // fallback: treat it as text
    return (
      <Box
        key={`text-${index}`}
        sx={{
          width: "80%",
          height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: stampColor,
          fontSize: "0.75rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {stampName}
      </Box>
    );
  };

  // Build an array of stamp boxes
  const stampsArray = Array.from({ length: maxScans }, (_, idx) => {
    const stampIndex = idx + 1;
    const isActive = stampIndex <= currentScans;
    const rewardForThisStamp = rewardLookup[stampIndex];

    return (
      <Box
        key={`stamp-wrapper-${idx}`}
        sx={{
          width: "calc(20% - 6px)",
          maxWidth: 40,
          aspectRatio: "1/1",
          borderRadius: "50%",
          backgroundColor: stampBackgroundColor,
          border: `1px solid ${stampOutlineColor}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "3px",
          position: "relative",
          cursor: rewardForThisStamp ? "pointer" : "default",
        }}
        // Only if there's a reward, we attach the click to open popover
        onClick={
          rewardForThisStamp
            ? (event) => handleOpenReward(event, rewardForThisStamp)
            : undefined
        }
      >
        {/* Render the main stamp content */}
        {renderStampContent(isActive, idx)}

        {/* Reward star overlay if this stamp has a reward */}
        {rewardForThisStamp && (
          <StarIcon
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "40%",
              height: "40%",
              color: isActive ? "#FFD700" : "#c7c7c7", // Gold if active, gray if inactive
            }}
          />
        )}
      </Box>
    );
  });

  // Layout for all stamps
  const stampCardContainerSx: SxProps<Theme> = {
    width: "100%",
    padding: "8px",
    backgroundColor: stampAreaBackgroundColor,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
  };

  return (
    <>
      <Box sx={stampCardContainerSx}>{stampsArray}</Box>

      {/* Reward Popover */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseReward}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ padding: 2, maxWidth: 200, textAlign: "center" }}>
          <Typography variant="body1">
            <strong>Reward:</strong> {rewardContent}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default StampCardNew;
