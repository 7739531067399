import React from "react";
import { TextField, SxProps, Theme, Box } from "@mui/material";
import { UserProfile } from "../../components/auth/firestore/getUserDetails";

interface ReadOnlyFieldsProps {
  userData: UserProfile | null;
  textFieldSx: SxProps<Theme>;
}

const ReadOnlyFields: React.FC<ReadOnlyFieldsProps> = ({
  userData,
  textFieldSx,
}) => {
  return (
    <Box>
      {/* Email */}
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        value={userData?.email || ""}
        disabled
        sx={textFieldSx}
        style={{ marginBottom: "1rem" }}
      />

      {/* Referral Code */}
      <TextField
        label="Referral Code"
        variant="outlined"
        fullWidth
        value={userData?.referralCode || ""}
        disabled
        sx={textFieldSx}
      />
    </Box>
  );
};

export default ReadOnlyFields;
