"use client";

import React, { useEffect, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Collapse,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

import { getLoyaltyCardDetails } from "../components/auth/firestore/dynamicStoreDetails";
import { useParams } from "react-router-dom";
import { UserContext } from "../contexts/user-context";
import { handleAddCardToWallet } from "../components/auth/firestore/addToWallet";

interface AddtoWalletStoreFrontProps {
  shortID: string;
}

const AddtoWalletStoreFront: React.FC<AddtoWalletStoreFrontProps> = () => {
  const navigate = useNavigate();
  const { shortID } = useParams<{ shortID: string }>();
  const context = React.useContext(UserContext);

  const [cardDetails, setCardDetails] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [showRewards, setShowRewards] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [formDisabled, setFormDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (shortID) {
      getLoyaltyCardDetails(shortID)
        .then((details: any) => {
          setCardDetails(details);
          setLoading(false);
        })
        .catch((error: Error) => {
          console.error("Error fetching loyalty card details:", error);
          setLoading(false);
        });
    }
  }, [shortID]);

  const handleAddToWallet = async (event: React.FormEvent) => {
    event.preventDefault();
    //add to wallet function
    setFormDisabled(true);
    setFormSubmitted(true);
    await handleAddCardToWallet(
      context?.user?.uid || "",
      cardDetails?.storeID || "",
      cardDetails?.id || ""
    ).then(() => {
      navigate("/wallet");
      // window.location.href = "/wallet";
    });
  };

  const handleRegister = (event: React.FormEvent) => {
    event.preventDefault();
    //navigate to register
    navigate(`/auth/sign-up?shortID=${shortID}`);
    setFormDisabled(true);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!cardDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Card sx={{ padding: 2 }}>
          <Typography variant="h6">Loyalty Card not found!</Typography>
        </Card>
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
          padding: 2,
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{cardDetails.storeName} | Stomp Loyalty</title>
          <meta
            name="keywords"
            content={`stomp, stomp card, loyalty card, store, rewards, stamp card, ${cardDetails.storeName}`}
          />{" "}
        </Helmet>
        <Card
          sx={{
            maxWidth: 600,
            boxShadow: 3,
            marginBottom: 3,
          }}
        >
          {cardDetails.titleImage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "95vw",
                maxHeight: "40vh",
                aspectRatio: "2/1",
              }}
            >
              <CardMedia
                component="img"
                image={cardDetails.titleImage}
                alt={`${cardDetails.storeName} Image`}
                sx={{ borderRadius: "4px 4px 0 0" }}
              />
            </Box>
          )}
          <CardContent
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              backgroundColor: cardDetails.cardBackgroundColor,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                sx={{ color: cardDetails.cardTextColor, fontWeight: 600 }}
              >
                {cardDetails.storeName}
              </Typography>
              {cardDetails.socialURL && (
                <a
                  href={cardDetails.socialURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon
                    sx={{
                      fontSize: 30,
                      color: "#E1306C",
                      marginLeft: 1,
                      marginBottom: "5px",
                    }}
                  />
                </a>
              )}
            </Box>

            {cardDetails.cardDescription && (
              <Typography
                variant="body2"
                color={cardDetails.cardTextColor}
                gutterBottom
              >
                {cardDetails.cardDescription}
              </Typography>
            )}
            {cardDetails.storeNumber && (
              <Typography
                variant="body1"
                color={cardDetails.cardTextColor}
                gutterBottom
              >
                Store Number: {cardDetails.storeNumber}
              </Typography>
            )}
            {cardDetails.city && (
              <Typography
                variant="body1"
                color={cardDetails.cardTextColor}
                gutterBottom
              >
                {cardDetails.city}, {cardDetails.province}
              </Typography>
            )}
            {cardDetails.rewards && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: cardDetails.cardTextColor,
                    cursor: "pointer",
                    marginTop: 1,
                    marginBottom: 0,
                  }}
                  onClick={() => setShowRewards(!showRewards)}
                >
                  <Typography
                    variant="body1"
                    sx={{ textDecoration: "underline" }}
                  >
                    Rewards
                  </Typography>{" "}
                </Box>
                <Collapse in={showRewards}>
                  <Box
                    sx={{
                      marginTop: -1,
                      color: cardDetails.cardTextColor,
                      fontSize: "0.8rem",
                    }}
                  >
                    <ul>
                      {cardDetails.rewards
                        .sort(
                          (
                            a: { scansRequired: number },
                            b: { scansRequired: number }
                          ) => a.scansRequired - b.scansRequired
                        )
                        .map(
                          (
                            reward: {
                              scansRequired: number;
                              reward: string;
                            },
                            index: number
                          ) => (
                            <li key={index}>
                              {reward.scansRequired} Scans - {reward.reward}
                            </li>
                          )
                        )}
                    </ul>
                  </Box>
                </Collapse>
              </>
            )}
            {cardDetails.disclaimer && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "grey",
                    cursor: "pointer",
                    marginTop: 1,
                    marginBottom: 0,
                  }}
                  onClick={() => setShowDisclaimer(!showDisclaimer)}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ textDecoration: "underline" }}
                  >
                    Terms and Conditions
                  </Typography>{" "}
                  *
                </Box>
                <Collapse in={showDisclaimer}>
                  <Box
                    sx={{
                      marginTop: 0,
                      color: "dark-grey",
                      fontSize: "0.8rem",
                    }}
                  >
                    <ul>
                      {cardDetails.disclaimer
                        .split("\n")
                        .map((point: any, index: number) => (
                          <li key={index}>{point}</li>
                        ))}
                    </ul>
                  </Box>
                </Collapse>
              </>
            )}
          </CardContent>
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: 3,
              padding: 2,
              backgroundColor: cardDetails.cardBackgroundColor,
            }}
          >
            <Typography variant="h5" component="div" gutterBottom>
              Get Stomp Card
            </Typography>
            <Box>
              {cardDetails?.active ? (
                context?.user ? (
                  <Button
                    variant="contained"
                    disabled={formDisabled}
                    color="secondary"
                    fullWidth
                    sx={{ borderRadius: "7px", marginTop: 2 }}
                    onClick={handleAddToWallet} // Function to add loyalty card
                  >
                    Add to Wallet
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={formDisabled}
                    color="error"
                    fullWidth
                    sx={{ borderRadius: "7px", marginTop: 2 }}
                    onClick={handleRegister} // Function to register and add loyalty card
                  >
                    Register Wallet
                  </Button>
                )
              ) : (
                <Button
                  variant="contained"
                  disabled
                  color="warning"
                  fullWidth
                  sx={{ borderRadius: "7px", marginTop: 2 }}
                >
                  Loyalty Program Not Active
                </Button>
              )}
            </Box>

            {formSubmitted && (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ marginTop: 2 }}
              >
                Redirecting to your wallet...
              </Typography>
            )}
          </Card>
        </Card>
      </Box>
    </HelmetProvider>
  );
};

export default AddtoWalletStoreFront;
