import React from "react";
import { Outlet } from "react-router-dom";
import ThinHeader from "./thinHeader";
// import FootBar from "../components/main/footbar";
import { Box } from "@mui/material";

const AuthLayout: React.FC = () => {
  return (
    <Box sx={{ pt: "50px" }}>
      <ThinHeader />
      <Outlet />
      {/* <FootBar /> */}
    </Box>
  );
};

export default AuthLayout;
