import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import DynamicWalletStore from "./WalletStore";

export default function WalletStorePage() {
  const { storeURL } = useParams<{ storeURL: string }>();
  if (!storeURL) {
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Stack spacing={1}>
        <DynamicWalletStore storeURL={storeURL} />
      </Stack>
    </Box>
  );
}
