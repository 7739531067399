import React, { useEffect, useState } from "react";
import { Box, Button, TextField, SxProps, Theme } from "@mui/material";
import {
  UserProfile,
  updateName,
} from "../../components/auth/firestore/getUserDetails";
import { User } from "firebase/auth";

interface NameFieldProps {
  user: User | null;
  userData: UserProfile | null;
  setUserData: React.Dispatch<React.SetStateAction<UserProfile>>;
  textFieldSx: SxProps<Theme>;
}

const NameField: React.FC<NameFieldProps> = ({
  user,
  userData,
  setUserData,
  textFieldSx,
}) => {
  const [editName, setEditName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    if (userData) {
      const combinedName = `${userData.firstName ?? ""} ${
        userData.lastName ?? ""
      }`.trim();
      setEditName(combinedName);
    }
  }, [userData]);

  const handleButtonClick = async () => {
    if (!isEditingName) {
      // If not editing, switch to editing
      setIsEditingName(true);
    } else {
      // If currently editing, attempt update
      if (!user?.uid) return;
      const [firstName, ...rest] = editName.trim().split(" ");
      const lastName = rest.join(" ");

      try {
        await updateName(user.uid, firstName, lastName);
        setUserData((prev) => ({
          ...prev,
          firstName,
          lastName,
        }));
        alert("Name updated!");
      } catch (error) {
        alert("Failed to update name. " + (error as Error).message);
      } finally {
        setIsEditingName(false);
      }
    }
  };

  return (
    <>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        value={editName}
        onChange={(e) => setEditName(e.target.value)}
        disabled={!isEditingName}
        sx={textFieldSx}
      />
      <Button
        variant="contained"
        sx={{ mt: 2, fontWeight: "bold", width: "100%" }}
        onClick={handleButtonClick}
      >
        {isEditingName ? "Update Name" : "Edit Name"}
      </Button>
    </>
  );
};

export default NameField;
