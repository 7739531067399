import {
  // Original icons
  AirplanemodeActive,
  Anchor,
  BakeryDining,
  BreakfastDining,
  Brightness7,
  BrunchDining,
  Cake,
  CalendarToday,
  Casino,
  Church,
  Coffee,
  Construction,
  ContentCut,
  DinnerDining,
  DirectionsBike,
  DirectionsCar,
  DownhillSkiing,
  DryCleaning,
  EmojiFoodBeverage,
  Fastfood,
  FitnessCenter,
  Flatware,
  Handyman,
  Hotel,
  Icecream,
  IceSkating,
  Kayaking,
  Kitesurfing,
  Liquor,
  LocalAtm,
  // 60 New Icons
  LocalBar,
  LocalDining,
  LocalFlorist,
  LocalGasStation,
  LocalGroceryStore,
  LocalHospital,
  LocalLaundryService,
  LocalLibrary,
  LocalMall,
  LocalMovies,
  LocalPharmacy,
  LocalPizza,
  LocalShipping,
  LocalTaxi,
  LunchDining,
  Mosque,
  Nightlife,
  OutdoorGrill,
  Paragliding,
  Park,
  Pets,
  Restaurant,
  RollerSkating,
  Sailing,
  ScubaDiving,
  SelfImprovement,
  Snowboarding,
  Snowshoeing,
  Spa,
  SportsBaseball,
  SportsBasketball,
  SportsCricket,
  SportsEsports,
  SportsGolf,
  SportsHockey,
  SportsMma,
  SportsMotorsports,
  SportsSoccer,
  SportsTennis,
  SportsVolleyball,
  Store,
  Synagogue,
  TempleBuddhist,
  WineBar,
} from '@mui/icons-material';

// Original 12 icons
const muiStamps = [
  { name: 'Coffee', component: Coffee },
  { name: 'Airplane', component: AirplanemodeActive },
  { name: 'Anchor', component: Anchor },
  { name: 'Tea', component: EmojiFoodBeverage },
  { name: 'Burger', component: LunchDining },
  { name: 'Fast Food', component: Fastfood },
  { name: 'Cake', component: Cake },
  { name: 'Calendar', component: CalendarToday },
  { name: 'Soccer Ball', component: SportsSoccer },
  { name: 'Scissors', component: ContentCut },
  { name: 'Pets', component: Pets },
  { name: 'Car', component: DirectionsCar },
  { name: 'Bike', component: DirectionsBike },

  // 60 NEW icons below
  { name: 'Bar', component: LocalBar },
  { name: 'Restaurant', component: Restaurant },
  { name: 'Florist', component: LocalFlorist },
  { name: 'Pizza', component: LocalPizza },
  { name: 'Spa', component: Spa },
  { name: 'Croissant', component: BakeryDining },
  { name: 'Grocery Store', component: LocalGroceryStore },
  { name: 'Pharmacy', component: LocalPharmacy },
  { name: 'Gas Station', component: LocalGasStation },
  { name: 'Mall', component: LocalMall },

  { name: 'Hospital', component: LocalHospital },
  { name: 'Library', component: LocalLibrary },
  { name: 'Hotel', component: Hotel },
  { name: 'Liquor Store', component: Liquor },
  { name: 'Wine Bar', component: WineBar },
  { name: 'Casino', component: Casino },
  { name: 'Movie Theater', component: LocalMovies },
  { name: 'Park', component: Park },
  { name: 'BBQ', component: OutdoorGrill },
  { name: 'Ice Cream', component: Icecream },

  { name: 'Brunch Spot', component: BrunchDining },
  { name: 'Breakfast Spot', component: BreakfastDining },
  { name: 'Dinner Spot', component: DinnerDining },
  { name: 'Gym', component: FitnessCenter },
  { name: 'Yoga Studio', component: SelfImprovement },
  { name: 'Hardware Store', component: Handyman },
  { name: 'Construction', component: Construction },
  { name: 'Laundry', component: LocalLaundryService },
  { name: 'Shipping', component: LocalShipping },

  { name: 'Basketball', component: SportsBasketball },
  { name: 'Baseball', component: SportsBaseball },
  { name: 'Cricket', component: SportsCricket },
  { name: 'Golf', component: SportsGolf },
  { name: 'Hockey', component: SportsHockey },
  { name: 'Tennis', component: SportsTennis },
  { name: 'Volleyball', component: SportsVolleyball },
  { name: 'Esports', component: SportsEsports },
  { name: 'MMA', component: SportsMma },
  { name: 'Motorsports', component: SportsMotorsports },

  { name: 'Kitesurfing', component: Kitesurfing },
  { name: 'Paragliding', component: Paragliding },
  { name: 'Sailing', component: Sailing },
  { name: 'Kayaking', component: Kayaking },
  { name: 'Snowboarding', component: Snowboarding },
  { name: 'Skiing', component: DownhillSkiing },
  { name: 'Snowshoeing', component: Snowshoeing },
  { name: 'Roller Skating', component: RollerSkating },
  { name: 'Ice Skating', component: IceSkating },
  { name: 'Scuba Diving', component: ScubaDiving },

  { name: 'Church', component: Church },
  { name: 'Mosque', component: Mosque },
  { name: 'Synagogue', component: Synagogue },
  { name: 'Temple', component: TempleBuddhist },
  { name: 'Night Club', component: Nightlife },
  { name: 'Taxi', component: LocalTaxi },
  { name: 'Store', component: Store },
  { name: 'Dining', component: LocalDining },
  { name: 'ATM', component: LocalAtm },
  { name: 'Dry Cleaning', component: DryCleaning },

  { name: 'Sun', component: Brightness7 },
  { name: 'Utensils', component: Flatware },
];

export default muiStamps;
