// src/components/popup/cardPopUp/CardPopup.tsx
import React from "react";
import { Dialog, DialogContent } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StoreCard } from "../../../types/detailsTypes";
import FrontPopUp from "./frontPopUp";
// import BackPopUp from "./backPopUp";

interface CardPopupProps {
  card: StoreCard;
  open: boolean;
  onClose: () => void;
}

const CardPopup: React.FC<CardPopupProps> = ({ card, open, onClose }) => {
  // This state will track if we show the back side or front side
  // const [showBack, setShowBack] = useState(false);

  // const handleToggleSide = () => {
  //   setShowBack((prev) => !prev);
  // };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 248, 240, 0.95)",
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
          borderRadius: 4,
        },
      }}
    >
      <DialogContent
        sx={{
          p: 0,
          position: "relative",
        }}
      >
        {/* <IconButton
          onClick={handleToggleSide}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10,
            backgroundColor: "#fff",
            transformRotate: showBack ? "90deg" : "0deg",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          <MoreVertIcon />
        </IconButton> */}

        {/* Conditionally render front or back */}
        {/* {!showBack ? <FrontPopUp card={card} /> : <BackPopUp card={card} />} */}
        <FrontPopUp card={card} />
      </DialogContent>
    </Dialog>
  );
};

export default CardPopup;
