// LogoutDialog.tsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

interface DialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const LogoutDialog: React.FC<DialogProps> = ({
  open,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirm Logout</DialogTitle>
      <DialogContent>Are you sure you want to log out?</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error">
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};